import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { makeNiceString, timeConverter, makeNiceRpsheetLinks } from './../utils/NiceStrings'
import { getPlayersOnlineAdmin, getTopRpList, getEventList, getRecentActivity} from './../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function AdminOverview() {
    const [whosOnline, setWhosOnline] = useState([]);
    const [roleplayActivity, setRoleplayActivity] = useState([]);
    const [realmEvents, setRealmEvents] = useState([]);
    const [recentActivity, setRecentActivity] = useState([]);
    const [recentStories, setRecentStories] = useState([]);

    const getSubmittedStoriesCard = () => {
        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>Recently Submitted Stories</Card.Title>
                </Card.Header>
                <Card.Body>
                    {recentStories.map((story, index) =>
                        <div key={index} className="divtext">
                            <span>{story.story_title}</span><br/>
                            <small>{story.story_owner} - {timeConverter(story.story_publishdate)}</small>
                            <br></br>
                        </div>
                    )}
                </Card.Body>
            </Card>

        );
    }

    const getRecentIssuesCard = () => {
        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>Recently Updated Issues</Card.Title>
                </Card.Header>
                <Card.Body>
                    {recentActivity.map((issue, index) =>
                        <div key={index} className="divtext">
                            <span>{issue.issue_description}</span><br/>
                            <small>{issue.issue_reporter} - {timeConverter(issue.issue_updated)}</small>
                            <br></br>
                        </div>
                    )}
                </Card.Body>
            </Card>

        );
    }
    const getEventsCard = () => {
        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>Ingame Events</Card.Title>
                </Card.Header>
                <Card.Body>

                    {(realmEvents === undefined) || (realmEvents.length === 0)
                        ? <p>There are currently <strong>no</strong> events in the realm - why not create one! see <a href="/intro/events">here</a> for details!</p>
                        : null}
                    {realmEvents.map((event, index) =>
                        <div key={index}>
                            <strong>{event.organiser}</strong> organised the following event in {event.location}
                            <p>{event.event}</p>
                            <p>{event.expires}</p>

                        </div>)}

                </Card.Body>
            </Card>
        );
    }

    const getTopRpCard = () => {
        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>Busiest Roleplayers of the Day</Card.Title>
                </Card.Header>
                <Card.Body>
                    {roleplayActivity.map((rper, index) =>
                        <Row key={index}>
                            <Col>{rper.userName}</Col>
                            <Col>{rper.rpxp}</Col>

                        </Row>)}

                </Card.Body>
            </Card>
        );
    }

    const getRoleplayStatusCard = () => {
        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>Roleplay Status of Active Players</Card.Title>
                </Card.Header>
                <Card.Body>
                    {whosOnline.filter(who => who.rpstatus.length > 0).map(who =>
                        <div key={who.userName}>
                            <p><strong>{who.userName}</strong> - {who.rpstatus}</p>
                        </div>

                    )}

                </Card.Body>
            </Card>
        );
    }

    const getSimpleWhoCard = () => {
        //console.log(whosOnline);
        const getLocationId = (location) => {
            let test = whosOnline.find(who => who.location === location);
            return test.locationid;
        };
        let oocplayers = [...new Set(whosOnline.filter(who => who.isooc === true).map(who => who.userName))];
        let idleplayers = [...new Set(whosOnline.filter(who => who.idle.length > 0).map(who => who.userName))];
        //console.log(whosOnline)
        let locations = [...new Set(whosOnline.map(who => 
            who.location
        ))];


        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>
                        <div className="d-flex">
                            Who's Online?
                            <div className="ml-auto">
                                <FontAwesomeIcon className="fa fa-trash fa-lg" icon={"sync-alt"} color="maroon" onClick={getWhoList} />
                            </div>
                        </div>
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    {locations.map(location =>
                        
                        <div key={location}>
                            <h6>{location} <small>({getLocationId(location)})</small></h6>
                            {whosOnline.filter(who => who.location === location).map(who =>
                                <a key={who.userName} className="dryLink p-2" href={`/player/rpsheet/${who.userName}`}>
                                    {who.userName}
                                </a>
                                
                            )}
                            <br />
                        </div>
                    )}
                    <br></br>
                    <p>{makeNiceRpsheetLinks(oocplayers)} {oocplayers.length > 1 ? " are " : " is "} <strong>out of character</strong></p>
                    <p>{makeNiceRpsheetLinks(idleplayers)} {idleplayers.length > 1 ? " are " : " is "} <strong>idle</strong></p>

                </Card.Body>
            </Card >
        );
    }


    const getIpList = () => {

        let ips = [...new Set(whosOnline.map(who => who.ipaddress))];

        return (
            <Card className={"m-2 score-card-eom"} >
                <Card.Header className={"going-on-header"}>
                    <Card.Title>IP Checking</Card.Title>
                </Card.Header>
                <Card.Body>

                    {ips.map(ip =>
                        <div key={ip}>
                            <h5>Connect from {ip}</h5>
                            {whosOnline.filter(who => who.ipaddress === ip).map(who =>
                                <div className="pl-3" key={who.userName}>
                                    {who.userName} using {who.mudclient}
                                </div>
                            )}
                            <br />
                        </div>
                    )}

                    <br />

                </Card.Body>
            </Card>
        );
    }

    // Retrieves the list of items from the Express app
    const getWhoList = async () => {
        let data = await getPlayersOnlineAdmin();
        //console.log(data);
        if (data.result) {
            setWhosOnline(data.users);
        } else {
            setWhosOnline([]);
        }
    }

    const getTopRpData = async () => {
        let data = await getTopRpList();
        //console.log('Top RP' ,data.rpdata);
        if (data.result)
            setRoleplayActivity(data.rpdata);
        else {
            setRoleplayActivity([]);
        }
    }

    const getEventData = async () => {
        let data = await getEventList();
        //console.log('Event' ,data.currentevents);
        if (data.result) {
            setRealmEvents([...data.currentevents, ...data.futureevents]);
        } else {
            setRealmEvents([]);
        }
    }
    
    const getRecentActivityData = async () => {
        let data = await getRecentActivity();
        //console.log('Recent' ,data);
        if (data.result) {
            data.issues.sort((a, b) => b.issue_updated - a.issue_updated);
            setRecentActivity(data.issues);
            setRecentStories(data.stories)
        } else {
            setRecentActivity([]);
            setRecentStories([])
        }
    }

    useEffect(() => {
        getWhoList();
        getTopRpData();
        getEventData();
        getRecentActivityData();

        const intervalRefresh = setInterval(() => {
            //console.log('Refresh page')
            getWhoList();
            getTopRpData();
            getEventData();
            getRecentActivityData();
        }, 60000);

        return () => {
            clearInterval(intervalRefresh);
        }

    }, []);

    return (
        <div className="container container-eom" id="divMainContent">
            <div style={{
                display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', alignItems: "flex-start",
                justifyContent: "flex-start"
            }}>
                <Row>
                    <Col>
                        {getSimpleWhoCard()}
                        {getIpList()}
                        {getRoleplayStatusCard()}
                        {getEventsCard()}
                        {getTopRpCard()}
                    </Col>
                    <Col>
                        {getSubmittedStoriesCard()}
                        {getRecentIssuesCard()}
                    </Col>
                </Row>


            </div>
        </div>
    )
}

export default AdminOverview;